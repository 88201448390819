import { loadingButtonClasses } from '@mui/lab/LoadingButton';
import { ThemeOptions, alertClasses, inputClasses } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';

import { ColorScale } from '@/theme/colors';

const fonts = ['Inter', '-apple-system', 'Helvetica Neue', 'sans-serif'].join(',');
const monoFonts = ['IBM Plex Mono', 'monaco', 'monospace'].join(',');
const marketingFonts = ['BDO', 'Inter', 'Helvetica Neue', 'sans-serif'].join(',');

const defaultPalette = {
  primary: {
    light: ColorScale.blue[300],
    main: ColorScale.blue[500],
  },
  secondary: {
    light: ColorScale.gray[300],
    main: ColorScale.gray[500],
  },
  error: {
    light: ColorScale.red[300],
    main: ColorScale.red[500],
    dark: ColorScale.red[700],
  },
  warning: {
    light: ColorScale.yellow[300],
    main: ColorScale.yellow[500],
  },
  info: {
    light: ColorScale.blue[300],
    main: ColorScale.blue[400],
  },
  success: {
    light: ColorScale.green[300],
    main: ColorScale.green[600],
  },
  background: {
    default: ColorScale.mono.white,
    muted: ColorScale.gray[100],
    subtle: ColorScale.gray[50],
    emphasis: ColorScale.gray[600],
    accent: ColorScale.blue[25],
    info: ColorScale.blue[50],
    infoEmphasis: ColorScale.blue[500],
    success: ColorScale.green[50],
    successEmphasis: ColorScale.green[600],
    warn: ColorScale.yellow[50],
    warnEmphasis: ColorScale.yellow[500],
    danger: ColorScale.red[50],
    dangerEmphasis: ColorScale.red[500],
  },
  border: {
    default: ColorScale.gray[200],
    muted: ColorScale.gray[100],
    subtle: ColorScale.gray[50],
    emphasis: ColorScale.gray[700],
    accent: ColorScale.blue[100],
    accentEmphasis: ColorScale.blue[600],
    info: ColorScale.blue[100],
    infoEmphasis: ColorScale.blue[500],
    success: ColorScale.green[100],
    successEmphasis: ColorScale.green[700],
    warn: ColorScale.yellow[100],
    warnEmphasis: ColorScale.yellow[600],
    danger: ColorScale.red[100],
    dangerEmphasis: ColorScale.red[600],
  },
  text: {
    default: ColorScale.mono.black,
    muted: ColorScale.gray[700],
    subtle: ColorScale.gray[500],
    accent: ColorScale.blue[500],
    success: ColorScale.green[600],
    warn: ColorScale.yellow[800],
    danger: ColorScale.red[600],
    emphasis: ColorScale.mono.white,
    disabled: ColorScale.mono.disabled,
  },
};

const defaultMUItheme = createTheme();

const defaultThemeConfig = {
  breakpoints: defaultMUItheme.breakpoints,
  palette: defaultPalette,
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
    '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
    '0px 4px 10px rgba(0, 0, 0, 0.12)',
    '0px 6px 12px rgba(0, 0, 0, 0.14)',
    ...Array(20).fill('none'),
  ],
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiFab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
        variant: 'outlined',
        color: 'secondary',
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxSizing: 'border-box',
          minWidth: 'auto',
          transition: 'all 0.15s ease-in-out',

          [`&.${buttonClasses.disabled}:not(.${buttonClasses.text}, .${loadingButtonClasses.loading})`]:
            {
              color: ColorScale.gray[300],
              background: ColorScale.gray[100],
              border: `1px solid ${defaultPalette.border.default}`,
            },
        },
        iconSizeMedium: {
          '> .MuiSvgIcon-fontSizeMedium': {
            fontSize: '16px',
          },
        },
        startIcon: {
          marginLeft: 0,
        },
        endIcon: {
          marginRight: 0,
        },
        sizeSmall: {
          fontSize: '.75rem',
          lineHeight: '16px',
          padding: '6px 12px',
        },
        sizeMedium: {
          fontSize: '.875rem',
          lineHeight: '16px',
          padding: '7px 12px',
        },
        sizeLarge: {
          fontSize: '1rem',
          lineHeight: '32px',
          padding: '8px 24px',
        },
        outlined: {
          color: defaultPalette.text.default,
          background: ColorScale.mono.white,
        },
        containedPrimary: {
          color: ColorScale.mono.white,
          background: defaultPalette.background.successEmphasis,
          border: `1px solid ${defaultPalette.border.successEmphasis}`,

          '&:hover, &:focus': {
            background: ColorScale.green[700],
            border: `1px solid ${ColorScale.green[800]}`,
          },
        },
        containedSecondary: {
          color: ColorScale.mono.white,
          background: defaultPalette.background.emphasis,
          border: `1px solid ${defaultPalette.border.emphasis}`,

          '&:hover, &:focus': {
            border: `1px solid ${ColorScale.gray[800]}`,
            background: ColorScale.gray[700],
          },
        },
        containedWarning: {
          color: ColorScale.mono.white,
          background: defaultPalette.background.warnEmphasis,
          border: `1px solid ${ColorScale.yellow[600]}`,

          '&:hover, &:focus': {
            background: ColorScale.yellow[600],
            border: `1px solid ${ColorScale.yellow[700]}`,
          },
        },
        containedInfo: {
          color: ColorScale.mono.white,
          background: defaultPalette.background.infoEmphasis,
          border: `1px solid ${defaultPalette.border.infoEmphasis}`,

          '&:hover, &:focus': {
            background: ColorScale.blue[600],
            border: `1px solid ${ColorScale.blue[700]}`,
          },
        },
        containedSuccess: {
          color: ColorScale.mono.white,
          background: defaultPalette.background.successEmphasis,
          border: `1px solid ${defaultPalette.border.successEmphasis}`,

          '&:hover, &:focus': {
            background: ColorScale.green[700],
            border: `1px solid ${ColorScale.green[800]}`,
          },
        },
        containedError: {
          color: ColorScale.mono.white,
          background: defaultPalette.background.dangerEmphasis,
          border: `1px solid ${defaultPalette.border.dangerEmphasis}`,

          '&:hover, &:focus': {
            background: ColorScale.red[600],
            border: `1px solid ${ColorScale.red[700]}`,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${defaultPalette.border.default}`,
          '&:hover, &:focus': {
            background: ColorScale.mono.white,
            border: `1px solid ${defaultPalette.border.emphasis}`,
          },
        },
        outlinedSecondary: {
          border: `1px solid ${defaultPalette.border.default}`,
          '&:hover, &:focus': {
            background: ColorScale.mono.white,
            border: `1px solid ${defaultPalette.border.emphasis}`,
          },
        },
        outlinedSuccess: {
          color: defaultPalette.text.success,
          border: `1px solid ${defaultPalette.border.success}`,
          '&:hover, &:focus': {
            background: ColorScale.green[50],
            border: `1px solid ${defaultPalette.border.successEmphasis}`,
          },
        },
        outlinedWarning: {
          color: defaultPalette.text.warn,
          border: `1px solid ${defaultPalette.border.warn}`,
          '&:hover, &:focus': {
            background: ColorScale.yellow[50],
            border: `1px solid ${defaultPalette.border.warnEmphasis}`,
          },
        },
        outlinedError: {
          color: defaultPalette.text.danger,
          border: `1px solid ${defaultPalette.border.danger}`,
          '&:hover, &:focus': {
            background: ColorScale.red[50],
            border: `1px solid ${defaultPalette.border.dangerEmphasis}`,
          },
        },
        outlinedInfo: {
          color: defaultPalette.text.accent,
          border: `1px solid ${defaultPalette.border.info}`,
          '&:hover, &:focus': {
            background: ColorScale.blue[50],
            border: `1px solid ${defaultPalette.border.infoEmphasis}`,
          },
        },
        textSecondary: {
          color: defaultPalette.text.muted,
          '&:hover, &:focus': {
            color: defaultPalette.text.default,
            background: ColorScale.gray[100],
          },
        },
        textPrimary: {
          color: defaultPalette.text.default,

          '&:hover, &:focus': {
            background: ColorScale.gray[100],
          },
        },
        textSuccess: {
          color: defaultPalette.text.success,

          '&:hover, &:focus': {
            background: defaultPalette.background.success,
          },
          svg: {
            color: defaultPalette.text.success,
          },
        },
        textError: {
          color: defaultPalette.text.danger,

          '&:hover, &:focus': {
            background: defaultPalette.background.danger,
          },
        },
        textInfo: {
          color: defaultPalette.text.accent,

          '&:hover, &:focus': {
            background: defaultPalette.background.info,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',

          '&:hover, &:focus': {
            background: ColorScale.gray[100],
          },
        },
        sizeSmall: {
          padding: '4px',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: '4px',
          gap: 4,
          border: `1px solid ${defaultPalette.border.default}`,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: defaultPalette.text.muted,
          textTransform: 'none',
          padding: '4px 12px',
          '&.Mui-selected': {
            color: defaultPalette.text.emphasis,
            backgroundColor: defaultPalette.background.emphasis,
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
            '&:not(:last-of-type)': {
              borderLeft: `0px solid ${defaultPalette.border.emphasis}`,
              borderRadius: '8px',
            },
            '&:not(:first-of-type)': {
              borderLeft: `0px solid ${defaultPalette.border.default}`,
              borderRadius: '8px',
            },
            '&:hover': {
              background: defaultPalette.background.emphasis,
              color: defaultPalette.text.emphasis,
            },
          },
          '&.MuiToggleButtonGroup-grouped': {
            '&:not(:last-of-type)': {
              border: `0px solid ${defaultPalette.border.emphasis}`,
              borderRadius: '8px',
            },
            '&:not(:first-of-type)': {
              border: `0px solid ${defaultPalette.border.default}`,
              borderRadius: '8px',
            },
          },
          '&:hover': {
            color: defaultPalette.text.default,
            background: defaultPalette.background.muted,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          marginRight: '0px',
        },
        label: {
          fontSize: '12px',
          fontWeight: '500',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: '500',
          marginBottom: '4px',
          color: defaultPalette.text.default,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          flex: 'auto',
          borderColor: defaultPalette.border.default,
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
        },
        columnHeaders: {
          backgroundColor: defaultPalette.background.subtle,
          borderColor: defaultPalette.border.default,
          fontSize: '14px',
        },
        footerContainer: {
          backgroundColor: defaultPalette.background.subtle,
          borderColor: defaultPalette.border.default,
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
        },
        columnHeader: {
          padding: '0 16px',
          '&:focus': {
            outline: 'none',
          },
        },
        row: ({ isClickable = true }) => ({
          ...(isClickable && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
        'cell--withRenderer': {
          padding: '16px',

          '&:focus-within, &:focus': {
            outline: 'none',
          },
        },
        cell: {
          borderColor: defaultPalette.border.default,
          fontSize: '14px',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        size: 'small',
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          width: '36px',
          height: '20px',
          padding: 0,
          margin: '0px 0px 0px 8px',
          overflow: 'unset',
        },
        switchBase: {
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: defaultPalette.background.infoEmphasis,
            border: `solid 1px ${defaultPalette.border.accentEmphasis}`,
          },
          '&.Mui-checked > .MuiSwitch-thumb': {
            backgroundColor: defaultPalette.background.default,
          },
          '.MuiSwitch-thumb': {
            width: '12px',
            height: '12px',
          },
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
        track: {
          borderRadius: 40,
          border: `solid 1px ${defaultPalette.border.default}`,
          backgroundColor: defaultPalette.background.default,
          opacity: 1,
          transition: 'all 0.1s ease-in-out',
          boxSizing: 'border-box',
        },
        thumb: {
          boxShadow: 'none',
          backgroundColor: defaultPalette.background.muted,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          input: {
            height: 'auto',
          },
        },
        multiline: {
          padding: '7px 12px',
        },
        notchedOutline: {
          border: 'none',
        },
        sizeSmall: {
          padding: '6px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: '16px',
          borderRadius: '8px',

          background: defaultPalette.background.default,
          border: `1px solid ${defaultPalette.border.default}`,
          input: {
            lineHeight: '16px',
            padding: '7px 12px',
          },

          [`&.${inputClasses.focused}`]: {
            border: `1px solid ${defaultPalette.border.emphasis}`,
          },
        },

        sizeSmall: {
          fontSize: '12px',
          maxHeight: '28px',
          padding: '6px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: '-4px',
          marginLeft: '8px',
          color: defaultPalette.text.subtle,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '6px 12px',
        },
        noOptions: {
          padding: '8px 16px',
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '> .MuiInputBase-root': {
            '&.MuiAutocomplete-inputRoot': {
              input: {
                padding: 0,
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '7px 12px',
          minHeight: 'auto',
        },
        icon: {
          right: '8px',
          top: 'auto',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          background: 'transparent',
          color: ColorScale.gray[900],
          '&.MuiSlider-valueLabelOpen': {
            transform: 'translateY(-60%) scale(1)',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        separator: {
          marginLeft: '4px',
          marginRight: '4px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${defaultPalette.border.default}`,
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${defaultPalette.border.default}`,
          background: defaultPalette.background.subtle,
          padding: '16px 24px',
          justifyContent: 'end',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: '4px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: defaultPalette.border.default,
          backgroundColor: defaultPalette.background.default,
          padding: 0,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        colorPrimary: {
          color: ColorScale.mono.white,
          borderColor: defaultPalette.border.emphasis,
          background: defaultPalette.background.emphasis,
        },
        colorSuccess: {
          color: ColorScale.green[700],
          borderColor: defaultPalette.border.successEmphasis,
          background: defaultPalette.background.success,
        },
        colorDefault: {
          color: defaultPalette.text.muted,
          borderColor: defaultPalette.border.default,
          background: defaultPalette.background.subtle,
        },
        colorSecondary: {
          color: defaultPalette.text.muted,
          borderColor: defaultPalette.border.default,
          background: defaultPalette.background.subtle,
        },
        colorError: {
          color: defaultPalette.text.danger,
          borderColor: defaultPalette.border.dangerEmphasis,
          background: defaultPalette.background.danger,
        },
        colorWarning: {
          color: defaultPalette.text.warn,
          borderColor: defaultPalette.border.warnEmphasis,
          background: defaultPalette.background.warn,
        },
        colorInfo: {
          color: defaultPalette.text.accent,
          borderColor: defaultPalette.border.infoEmphasis,
          background: defaultPalette.background.info,
        },
        sizeSmall: {
          height: 'auto',
          border: '1px solid',
          borderRadius: 8,
          padding: '3px 6px',
          fontSize: 12,
          fontWeight: 500,
          display: 'flex',
          gap: 4,
          lineHeight: '14px',
        },
        iconSmall: {
          marginLeft: 0,
          marginRight: 0,
          width: 14,
          height: 14,
        },
        avatarSmall: {
          marginLeft: 0,
          marginRight: 0,
          width: 14,
          height: 14,
          color: 'inherit',
        },
        labelSmall: {
          padding: '0',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      // TODO (VAHAN) this is a temporary configuration
      styleOverrides: {
        content: {
          margin: '16px 0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'inherit',
          marginRight: 16,
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          'li:first-of-type': {
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
          },
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: {
          maxHeight: 62,
          'a.MuiListItemButton-root:hover': {
            color: 'inherit',
          },
          '&.Mui-selected': {
            color: defaultPalette.text.emphasis,
            borderRadius: '8px',
            background: defaultPalette.background.infoEmphasis,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          maxHeight: 62,
          lineHeight: 'inherit',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 12,
          marginBottom: 4,
        },
        secondary: {
          fontSize: 12,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          padding: '8px 16px',
          borderRadius: '8px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          textAlign: 'left',
          fontWeight: '500',
          transition: 'background 0.15s ease-in-out',
          color: defaultPalette.text.muted,
          minWidth: 'auto',

          '&.Mui-selected': {
            color: defaultPalette.text.default,
          },
          '&:hover': {
            color: defaultPalette.text.default,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: defaultPalette.border.successEmphasis,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: false,
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        root: {
          marginTop: '4px',
          '&.MuiTableContainer-root': {
            boxShadow: `
              0px 0px 0px -1px rgb(0 0 0 / 20%),
              0px 0px 1px 0px rgb(0 0 0 / 14%),
              0px 1px 2px 0px rgb(0 0 0 / 12%)
            `,
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        paper: {
          border: `1px solid ${defaultPalette.border.muted}`,
          borderRadius: 8,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        list: {
          padding: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          padding: '6px 8px',
          borderRadius: '8px',
          minWidth: '150px',
          '&:not(:last-of-type)': {
            marginBottom: 4,
          },
          '&.Mui-disabled.Mui-selected': {
            background: defaultPalette.background.default,
            color: defaultPalette.text.muted,
          },
          '&.Mui-selected': {
            background: defaultPalette.background.muted,
            '&:hover': {
              background: defaultPalette.background.muted,
            },
          },
          '&:hover': {
            background: defaultPalette.background.muted,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          padding: 0,
        },
        standardWarning: {
          backgroundColor: defaultPalette.background.warn,
          border: `1px solid ${defaultPalette.border.warnEmphasis} !important`,
        },
      },
      variants: [
        {
          props: { variant: 'subtle' },
          style: {
            background: defaultPalette.background.subtle,
            color: defaultPalette.text.default,

            [`& .${alertClasses.icon}`]: {
              color: ColorScale.gray[400],
              opacity: 1,
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // This disables the calt feature in the Inter font, which causes "x" to be rendered as "×" in some cases
          fontFeatureSettings: "'calt' 0",
        },
      },
    },
  },
  typography: {
    title: {
      fontSize: 40,
      lineHeight: '1.1em',
      fontWeight: 500,
      fontFamily: marketingFonts,
    },
    h1: {
      fontSize: '32px',
      fontWeight: 500,
      margin: 0,
      lineHeight: '40px',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 500,
      margin: 0,
      lineHeight: '32px',
    },
    h3: {
      fontSize: '22px',
      fontWeight: 500,
      margin: 0,
      lineHeight: '25px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 500,
      margin: 0,
      lineHeight: '21px',
    },
    h5: {
      fontSize: '14px',
      fontWeight: 500,
      margin: 0,
      lineHeight: '16px',
    },
    h6: {
      fontSize: '12px',
      fontWeight: 500,
      margin: 0,
      lineHeight: '14px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
    mono1: {
      fontSize: '14px',
      fontFamily: monoFonts,
      letterSpacing: '-0.28px',
    },
    mono2: {
      fontSize: '12px',
      fontFamily: monoFonts,
      letterSpacing: '-0.28px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
    },
    caption: {
      fontSize: '12px',
      fontWeight: 500,
    },
    display1: {
      fontSize: '16px',
      lineHeight: '1.6em',
      fontFamily: marketingFonts,
    },
    display2: {
      fontSize: '14px',
      lineHeight: '1.14em',
      fontFamily: marketingFonts,
    },
    fontFamily: fonts,
    monoFontFamily: monoFonts,
  },
  zIndex: {
    // this is following the MUI zindex values (https://mui.com/customization/z-index/)
    // sits between the modal z-index and the snackbar z-index values
    autocomplete: 1350,
    graphActions: 6,
  },
};

type BasetenTheme = typeof defaultThemeConfig;

const defaultTheme = createTheme(defaultThemeConfig as unknown as ThemeOptions);

export {
  BasetenTheme,
  defaultPalette,
  defaultTheme,
  defaultThemeConfig,
  fonts,
  marketingFonts,
  monoFonts,
};
