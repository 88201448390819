enum BasetenPageEnum {
  Home = 1,
  Workspace,
  Application,
  Settings,
  Account,
  Class,
  Model,
  Chain,
  DeployModelViaTruss,
  DeployModel,
  View,
  ViewOperator,
  Worklet,
  Code,
  Onboarding,
  ModelBuilder,
}

interface BasetenPageProps {
  page: BasetenPageEnum;
  path: string | string[];
  /**
   * Paths matched by nested pages
   */
  subPaths?: string[];
  /**
   * Props passed into the component being rendered
   */
  additionalProps?: Record<string, unknown>;
}

type Page = BasetenPageEnum | string;

type ReadOnlySearchParams = Omit<URLSearchParams, 'append' | 'set' | 'delete' | 'sort'>;

export { BasetenPageEnum, BasetenPageProps, Page, ReadOnlySearchParams };
