import { Link, Stack, Typography } from '@mui/material';

import ActivityIcon from '@/components/Icons/ActivityIcon';
import ChevronsUpIcon from '@/components/Icons/ChevronsUpIcon';
import ShieldIcon from '@/components/Icons/ShieldIcon';
import { links } from '@/localization';

import FeatureItem from './FeatureItem/FeatureItem';
import { PartnerLogo } from './styled';

function About() {
  return (
    <Stack
      direction="column"
      spacing={4}
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ maxWidth: 400 }}
      mx={{ xs: 0, md: 4 }}
    >
      <Stack gap={2}>
        <Typography variant="h3">
          Fast and scalable model inference in{' '}
          <Typography variant="h3" color="text.success" component="span">
            our cloud
          </Typography>{' '}
          or{' '}
          <Typography variant="h3" color="text.success" component="span">
            yours
          </Typography>
        </Typography>
        <Typography variant="body1" color="text.muted">
          Effortlessly deploy open source and custom models on the fastest, most secure model
          delivery network. Want to learn more?{' '}
          <Link href={links.scheduleADemo} target="_blank">
            Schedule a demo
          </Link>
        </Typography>
      </Stack>
      <Stack gap={2}>
        <FeatureItem
          icon={<ChevronsUpIcon color="success" />}
          title="Highly performant infra"
          description="Go from prototype to production. As your traffic increases, our infrastructure
            automatically scales to keep up with it."
        />
        <FeatureItem
          icon={<ActivityIcon color="success" />}
          title="Effortless model management"
          description="Reliable logging and monitoring for every model to ensure there&#39;s visibility into
            what&#39;s happening under the hood at every step."
        />
        <FeatureItem
          icon={<ShieldIcon color="success" />}
          title="Secure and reliable"
          description="Enterprise-grade security and reliability comes out-of-the-box. Plus, the option to
            deploy on your own cloud so your models and data never leave your VPC."
        />
      </Stack>
      <Stack direction="column" gap={3} justifyContent="flex-start" alignItems="flex-start">
        <Typography variant="h5">Trusted by top engineering and machine learning teams</Typography>
        <Stack
          direction="row"
          alignItems="center"
          columnGap={2}
          rowGap={1}
          useFlexGap
          flexWrap="wrap"
        >
          <PartnerLogo src="/static/logos/descript.svg" />
          <PartnerLogo src="/static/logos/patreon.svg" />
          <PartnerLogo src="/static/logos/writer.svg" />
          <PartnerLogo src="/static/logos/picnichealth.svg" />
          <PartnerLogo src="/static/logos/motive.svg" />
          <PartnerLogo src="/static/logos/pipe.svg" />
          <PartnerLogo src="/static/logos/rime.svg" />
          <PartnerLogo src="/static/logos/stability.svg" />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default About;
